import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: { name: "DashBoard" } },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("token")) {
        return next({
          name: "/",
        });
      }
      next();
    },
  },
  {
    path: "/",
    name: "DashBoard",
    component: () => import("@/views/DashBoard"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/report",
    name: "Report",
    component: () => import("@/views/Report"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user-report",
    name: "UserReport",
    component: () => import("@/views/UserReport"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inquiry",
    name: "InquiryManage",
    component: () => import("@/views/InquiryManage"),
    meta: {
      requiresAuth: true,
    },
  },

  // 설문 토픽
  {
    path: "/campaigns",
    name: "CampaignManage",
    component: () => import("@/views/campaign/CampaignManage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/detail/:id",
    name: "CampaignDetail",
    component: () => import("@/views/campaign/CampaignDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaigns/report",
    name: "CampaignReport",
    component: () => import("@/views/campaign/CampaignReport"),
    meta: {
      requiresAuth: true,
    },
  },

  // 클라이언트
  {
    path: "/clients",
    name: "ClientManage",
    component: () => import("@/views/client/ClientManage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/clients/detail/:id",
    name: "ClientDetail",
    component: () => import("@/views/client/ClientDetail"),
    meta: {
      requiresAuth: true,
    },
  },

  // 언론사
  {
    path: "/medias",
    name: "MediaManage",
    component: () => import("@/views/media/MediaManage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/analysis",
    name: "MediaAnalysis",
    component: () => import("@/views/media/MediaAnalysis"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/live",
    name: "MediaLive",
    component: () => import("@/views/media/MediaLive"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/report",
    name: "MediaReport",
    component: () => import("@/views/media/MediaReport"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/detail/:id",
    name: "MediaDetail",
    component: () => import("@/views/media/MediaDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/categories",
    name: "CategoryManage",
    component: () => import("@/views/media/CategoryManage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/calculate",
    name: "MediaCalculate",
    component: () => import("@/views/media/MediaCalculate"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/medias/article-report",
    name: "ArticleReport",
    component: () => import("@/views/media/ArticleReport"),
    meta: {
      requiresAuth: true,
    },
  },

  // 대행사
  {
    path: "/agencies",
    name: "AgencyManage",
    component: () => import("@/views/agency/AgencyManage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agencies/calculate",
    name: "AgencyCalculate",
    component: () => import("@/views/agency/AgencyCalculate"),
    meta: {
      requiresAuth: true,
    },
  },

  // 기타
  {
    path: "/search",
    name: "LogSearch",
    component: () => import("@/views/etc/LogSearch"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!localStorage.getItem("token")) {
      return next({
        name: "Login",
      });
    } else {
      next();
    }
  }
  next();
});

export default router;
