import axios from "axios";
// import store from "@/store";
import router from "@/router";

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
    role: "",
  },

  getters: {
    authenticated(state) {
      return state.token;
    },

    user(state) {
      return state.user;
    },

    role(state) {
      return state.role;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, data) {
      state.user = data;
    },

    SET_ROLE(state, data) {
      state.role = data;
    },
  },

  actions: {
    async login({ dispatch }, payload) {
      await axios
        .post("api/v1/auth/login", payload)
        .then((res) => {
          dispatch("attempt", res.data.access_token);
        })
        .catch(() => {
          alert("로그인에 실패하였습니다. 이메일과 비밀번호를 확인해주세요.");
        });
    },

    async attempt({ commit, state, dispatch }, token) {
      if (token) {
        commit("SET_TOKEN", token);
      }

      if (!state.token) {
        return;
      }

      try {
        let response = await axios.get("api/v1/auth/me");
        commit("SET_USER", response.data.user);
        localStorage.setItem("media_id", response.data.user.media_id);
        commit("SET_ROLE", response.data?.roles[0]);
        localStorage.setItem("role", response.data?.roles[0]);
      } catch (error) {
        dispatch("logout");
      }
    },

    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
      commit("SET_ROLE", null);
      localStorage.clear();
      router.replace({ name: "Login" }).catch(() => {});
    },
  },
};
