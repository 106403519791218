<template>
  <v-app :style="backStyle" style="background-color: #efeff7">
    <v-navigation-drawer
      v-model="drawer"
      v-if="authenticated"
      style="background-color: #0d0f16"
      dark
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            style="cursor: pointer"
            class="text-h5 text-left mt-2 mb-1"
            @click="changeHome()"
          >
            Metavey Console
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav dense style="text-align: left">
        <v-list-item-group>
          <v-list v-for="item in nav.navItems" :key="item.subHeader" subheader>
            <v-subheader>{{ item.subHeader }}</v-subheader>
            <template>
              <v-list-item
                v-for="menu in item.menu"
                :key="menu.title"
                link
                :to="menu.link"
                active-class="blue--text"
                exact
                @click="move(menu.link)"
              >
                <v-list-item-icon>
                  <v-icon>{{ menu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-card v-if="authenticated">
      <v-app-bar color="white" app class="ma-3">
        <v-app-bar-nav-icon color="#0f131d" @click.stop="drawer = !drawer" />
        <v-select
          v-model="pageItem"
          :items="page_items"
          item-text="name"
          item-value="link"
          class="mt-6 ml-1"
          style="max-width: 200px"
          @change="move(pageItem)"
        ></v-select>
        <v-spacer></v-spacer>
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              color="#0F131D"
              text
              class="pr-0"
              style="min-width: 24px"
            >
              <v-icon>mdi-account-cog</v-icon>
              <span v-if="width > 1024" class="ml-1">{{ name }}님</span>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item>
              <v-list-item-avatar color="#0F131D" size="48">
                <span
                  class="white--text font-weight-bold"
                  style="color: #0f131d"
                >
                  관리
                </span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="title"
                  >{{ name }}님
                </v-list-item-title>
                <v-list-item-title class="mt-1"> {{ email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" />

            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>로그아웃</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-card>

    <v-main style="width: 100%">
      <router-view />
    </v-main>

    <Alert
      v-if="alertActive"
      :alertActive="alertActive"
      :message="alertMessage"
    />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import nav from "@/constants/navMenu.js";
import Alert from "@/components/common/Alert.vue";

export default {
  name: "App",
  components: {
    Alert,
  },
  data() {
    return {
      nav,
      drawer: true,
      name: "",
      email: "",
      token: "",
      page_items: [
        { name: "대시보드", link: "/" },
        { name: "종합 리포트", link: "/report" },
        { name: "접속 리포트", link: "/user-report" },
        { name: "상담 관리", link: "/inquiry" },
        { name: "설문 토픽 관리", link: "/campaigns" },
        { name: "설문 토픽 리포트", link: "/campaigns/report" },
        { name: "클라이언트 관리", link: "/clients" },
        { name: "대행사 관리", link: "/agencies" },
        { name: "대행사 정산", link: "/agencies/calculate" },
        { name: "언론사 관리", link: "/medias" },
        { name: "언론사 분석", link: "/medias/analysis" },
        { name: "카테고리 관리", link: "/medias/categories" },
        { name: "언론사 라이브", link: "/medias/live" },
        { name: "언론사 리포트", link: "/medias/report" },
        { name: "기사 리포트", link: "/medias/article-report" },
        { name: "언론사 정산", link: "/medias/calculate" },
        { name: "로그", link: "/search" },
      ],
      pageItem: null,
      media_id: null,
      backStyle: "margin-top: 40px;",
      width: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      alertActive: "alert/alertActive",
      alertMessage: "alert/alertMessage",
    }),
  },
  updated() {
    this.updateAuthMe();
    window.scrollTo(0, 0);
  },
  watch: {
    $route(to) {
      if (to.path === "/login") {
        this.backStyle = null;
      }
    },
    user(v) {
      this.name = v.name;
      this.backStyle = "margin-top: 40px;";
    },
  },
  mounted() {
    this.updateAuthMe();
    this.token = localStorage.getItem("token");
    if (this.token) {
      this.name = this.user.name;
      this.email = this.user.email;
      this.media_id = this.user.media_id;
    }
    const path = this.$router.currentRoute.path;
    if (typeof path === "undefined" || path === "") {
      this.pageItem = "/";
    } else {
      this.pageItem = path;
    }

    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });
  },
  methods: {
    updateAuthMe() {
      this.$store.dispatch("auth/attempt", localStorage.getItem("token"));
    },
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.resetStore();
      this.$store.dispatch("auth/logout");
    },
    changeHome() {
      this.resetStore();
      if (this.$route.path !== "/") this.$router.push("/");
      else location.reload();
    },
    resetStore() {
      this.$store.commit("SET_PLATFORM", []);
      this.$store.commit("SET_TYPE", []);
      this.$store.commit("SET_SEARCH", "");
      this.$store.commit("SET_DATERANGES", []);
      this.$store.commit("SET_PAGE", 1);
      this.$store.commit("SET_OFF_PAGE", 1);
    },

    move(item) {
      if (typeof item === "undefined") {
        this.pageItem = "/";
        if (this.$route.path !== this.pageItem) {
          this.$router.push(this.pageItem);
        }
      } else {
        this.pageItem = item;
        if (this.$route.path !== this.pageItem) {
          this.$router.push(this.pageItem);
        }
      }
    },
  },
};
</script>

<style>
html {
  background-color: #efeff7;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0f131d;
}
.v-list.v-select-list {
  text-align: left;
}
.sticky_header {
  position: sticky;
  top: 78px;
  background-color: #ffffff;
  z-index: 2;
}
@media all and (max-width: 1023px) {
  .sticky_header {
    position: unset;
  }
}
</style>
