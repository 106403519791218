<template>
  <v-snackbar v-model="alertActive" :vertical="true">
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn color="red" text v-bind="attrs" @click="closeAlert"> 닫기 </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Alert",
  props: {
    alertActive: Boolean,
    message: String,
  },

  methods: {
    ...mapMutations({
      closeAlert: "alert/closeAlert",
    }),
  },
};
</script>
