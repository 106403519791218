import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"; // 새로고침 시, 유지

import auth from "./auth";
import contact from "./contact";
import alert from "./alert";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage, // store를 session storage 에 유지
    }),
  ],
  state: {
    errors: false,
    publicData: [],
    mediaId: null,
    platform: [],
    type: [],
    dateRanges: [],
    search: "",
    page: 1,
    off_page: 1,
  },

  getters: {
    errors: (state) => {
      return state.errors;
    },
    getMediaId(state) {
      return state.mediaId;
    },
    getPlatform(state) {
      return state.platform;
    },
    getType(state) {
      return state.type;
    },
    getSearch(state) {
      return state.search;
    },
    getDateRanges(state) {
      return state.dateRanges;
    },
    getPage(state) {
      return state.page;
    },
    getOffPage(state) {
      return state.off_page;
    },
  },

  mutations: {
    SET_ERROR(state, errors) {
      state.errors = errors;
    },
    SET_MEDIA_ID(state, payload) {
      state.mediaId = payload;
    },
    SET_PLATFORM(state, payload) {
      state.platform = payload;
    },
    SET_TYPE(state, payload) {
      state.type = payload;
    },
    SET_SEARCH(state, payload) {
      state.search = payload;
    },
    SET_DATERANGES(state, payload) {
      state.dateRanges = payload;
    },
    SET_PAGE(state, payload) {
      state.page = payload;
    },
    SET_HOLD_PAGE(state, payload) {
      state.hold_page = payload;
    },
    SET_OFF_PAGE(state, payload) {
      state.off_page = payload;
    },
  },

  actions: {
    setError({ commit }, errors) {
      commit("SET_ERROR", errors);
    },
  },

  modules: {
    auth,
    contact,
    alert,
  },
});
