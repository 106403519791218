export default {
  navItems: [
    {
      subHeader: "홈",
      menu: [
        {
          title: "대시보드",
          link: "/",
          icon: "mdi-view-dashboard",
        },
        {
          title: "종합 리포트",
          link: "/report",
          icon: "mdi-chart-line",
        },
        {
          title: "접속 리포트",
          link: "/user-report",
          icon: "mdi-chart-line",
        },
        {
          title: "상담 관리",
          link: "/inquiry",
          icon: "mdi-frequently-asked-questions",
        },
      ],
    },
    {
      subHeader: "설문 토픽",
      menu: [
        {
          title: "설문 토픽 관리",
          link: "/campaigns",
          icon: "mdi-playlist-plus",
        },
        {
          title: "설문 토픽 리포트",
          link: "/campaigns/report",
          icon: "mdi-chart-line",
        },
      ],
    },
    {
      subHeader: "클라이언트",
      menu: [
        {
          title: "클라이언트 관리",
          link: "/clients",
          icon: "mdi-playlist-plus",
        },
        {
          title: "대행사 관리",
          link: "/agencies",
          icon: "mdi-playlist-plus",
        },
        {
          title: "대행사 정산",
          link: "/agencies/calculate",
          icon: "mdi-calculator",
        },
      ],
    },
    {
      subHeader: "언론사",
      menu: [
        {
          title: "언론사 관리",
          link: "/medias",
          icon: "mdi-playlist-plus",
        },
        {
          title: "언론사 분석",
          link: "/medias/analysis",
          icon: "mdi-chart-box-outline",
        },
        {
          title: "카테고리 관리",
          link: "/medias/categories",
          icon: "mdi-file-tree",
        },
        {
          title: "언론사 라이브",
          link: "/medias/live",
          icon: "mdi-playlist-play",
        },
        {
          title: "언론사 리포트",
          link: "/medias/report",
          icon: "mdi-chart-line",
        },
        {
          title: "기사 리포트",
          link: "/medias/article-report",
          icon: "mdi-chart-line",
        },
        {
          title: "언론사 정산",
          link: "/medias/calculate",
          icon: "mdi-calculator",
        },
      ],
    },
    {
      subHeader: "기타",
      menu: [
        {
          title: "로그",
          link: "/search",
          icon: "mdi-history",
        },
      ],
    },
  ],
};
