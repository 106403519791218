import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/lib/util/colors";
import "vuetify/dist/vuetify.min.css";
import axios from "axios";
import "@mdi/font/css/materialdesignicons.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Swal from "sweetalert2";
import moment from "moment";

require("./store/subscriber");

Vue.use(Vuetify);

window.axios = require("axios");
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.headers.common["role"] = "super";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response.status === 500) {
    //   store.dispatch("auth/logout");
    //   router.push("/login").catch(()=>{});
    //   alert("일시적인 장애로 잠시 후 이용해 주십시오.");
    // }
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push("/login").catch(() => {});
    }
    return Promise.reject(error);
  }
);

Vue.config.productionTip = false;
Vue.prototype.axios = window.axios;

Object.defineProperty(Vue.prototype, "$Swal", {
  value: Swal,
});
Object.defineProperty(Vue.prototype, "$moment", {
  value: moment,
});
store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    vuetify: new Vuetify({
      theme: {
        themes: {
          light: {
            primary: "#1B1E2B",
          },
          dark: {
            primary: colors.purple.darken3,
          },
        },
      },
      icons: {
        iconfont: "mdiSvg",
      },
    }),
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
